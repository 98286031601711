; (function(angular) {

  'use strict';

  angular
    .module('catcherSearch')
    .config(
      [
        '$routeProvider',
        function($routeProvider) {
          $routeProvider
            .when('/search', {
              templateUrl: 'catcher/search/search.partial.html',
              controller: 'searchController'
            })
            .when('/listing', {
              templateUrl: 'catcher/search/listing.partial.html',
              controller: 'listerController'
            });
        }
      ]
    );

}(window.angular));
