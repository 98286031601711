; (function(angular) {

  'use strict';

  angular
    .module('catcherUtils')
    .directive('toNumber', [
      function () {
        return {
          require: 'ngModel',
          link: function (scope, element, attrs, ctrl) {
            ctrl.$parsers.push(function (value) {
              value.replace('#', '');
              return (value.replace(/\D/g,'') || '');
            });
          }
        };
      }
    ]);

}(window.angular));

