; (function(angular, _) {

  'use strict';

  angular
    .module('catcherMessaging')
    .controller('supportController', [
      '$scope',
      '$rootScope',
      '$location',
      '$routeParams',
      'userService',
      'errorService',
      'messageService',
      function($scope, $rootScope, $location, $routeParams, userService, errorService, messageService) {

        userService.isUserLoggedIn(function(error) {
          if(error) {
            errorService.delegateError(error);
          }
        });

        $scope.showMessage = false;
        $scope.messageWating = false;
        $scope.composingNewMessage = false;

        $scope.goToMessage = goToMessage;

        function goToMessage (message) {
          $location.path('/support/' + message.id);
        }

        messageService.getAllMessages(function(error, messages) {
          if(error) {
            errorService.delegateError(error);
          } else {
            $rootScope.messages = messages;
          }
        });

        if($routeParams.messageId !== undefined) {
          $scope.$watch('messages', function() {
            $scope.messageWating = true;
            _.each(
              $scope.messages, 
              function(message, index) {
                if(message.id === $routeParams.messageId) {
                  $scope.showMessage = message;

                  if(message.contains_unread) {
                    messageService.markMessageAsRead(message.id, function(error, data) {
                      $scope.messages[index].contains_unread = false;
                      message.contains_unread = false;
                    });
                  }

                  messageService.updateUnreadMessagesFlag($scope.messages, true);

                  return false;
                } else if(message.replies.length) {
                  _.each(
                    message.replies,
                    function(replyMessage) {
                      if(replyMessage.id === $routeParams.messageId) {
                        $scope.showMessage = message;
                        return false;
                      }
                    }
                  );
                }
              }
            );

            $scope.messageWating = false;
            if($scope.showMessage === false) {
              // TODO DEAL WITH THIS
              // $location.path('/support');
            }
          });
        }
      }
    ]);

}(window.angular, window._));
