; (function(angular, _) {

  'use strict';

  angular
    .module('catcherSearch')
    .controller('notificationSettingsModalController', [
      '$scope',
      'notificationService',
      'soundNotificationService',
      function($scope, notificationService, soundNotificationService) {

        $scope.notifications = {
          buttonText: 'Enable notifications',

          buttonClass: {
            active: false,
          }
        };

        $scope.soundNotifications = {
          buttonText: 'Enable sound notifications',

          buttonClass: {
            active: false,
          }
        };

        $scope.toggleNotifications         = toggleNotifications;
        $scope.toggleSoundNotifications    = toggleSoundNotifications;
        $scope.notificationsSupported      = notificationService.doesBrowserSupportNotification;
        $scope.soundNotificationsSupported = notificationService.doesBrowserSupportNotification;

        updateNotifications(notificationService.isEnabled);
        updateSoundNotifications(soundNotificationService.isEnabled);

        function toggleNotifications () {
          notificationService.toggle(updateNotifications);
        }

        function toggleSoundNotifications () {
          soundNotificationService.toggle(updateSoundNotifications);
        }

        function updateNotifications (isEnabled) {
          if (isEnabled) {
            $scope.notifications.buttonText = 'Disable notifications';
          } else {
            $scope.notifications.buttonText = 'Enable notifications';
          }

          $scope.notifications.buttonClass.active = isEnabled;
        }

        function updateSoundNotifications (isEnabled) {
          if (isEnabled) {
            $scope.soundNotifications.buttonText = 'Disable sound notifications';
          } else {
            $scope.soundNotifications.buttonText = 'Enable sound notifications';
          }

          $scope.soundNotifications.buttonClass.active = isEnabled;
        }

      }
    ]);

}(window.angular, window._));
