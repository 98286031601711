; (function (angular, _, moment) {

  'use strict';

  angular
    .module('catcherSearch')
    .controller('listerController', [
      '$scope',
      '$location',
      '$filter',
      '$modal',
      'userService',
      'requestService',
      'notificationService',
      'soundNotificationService',
      'errorService',
      function ($scope, $location, $filter, $modal, userService, requestService, notificationService, soundNotificationService, errorService) {
        // cache of preexisting item references
        var existingItemIdReference = {};

        // Setup scope variables
        setupScope();

        // Run synchronous controller procedures
        init();

        function setupScope () {
          // For all shown listing items
          $scope.listingItems = [];

          // For all paused listing items
          $scope.pausedListingItems = [];
          $scope.isPaused           = false;

          $scope.requestErrorMessage = false;
          $scope.requestErrorStatus  = false;
          $scope.timeNow             = moment();

          $scope.isStuck             = false;

          $scope.getLocation                   = getLocation;
          $scope.getLocationFlag               = getLocationFlag;
          $scope.showLocationFlag              = showLocationFlag;
          $scope.getPostageType                = getPostageType;
          $scope.returnToSearch                = returnToSearch;
          $scope.openItem                      = openItem;
          $scope.openItemSearchUrl             = openItemSearchUrl;
          $scope.togglePausedListings          = togglePausedListings;
          $scope.showNotificationSettingsModal = showNotificationSettingsModal;
        }

        function getLocation (item) {
          return item.location.split(',').join(', ');
        }

        function getLocationFlag (item) {
          return item.country.toLowerCase() + '.svg';
        }

        function showLocationFlag (item) {
          return getLocationFlag(item) !== '';
        }

        function getPostageType (item) {
          if (item.postageType == 'FreePickup') return 'Free Pickup';
          if (item.postageType == 'Free')       return 'Free';

          if (item.postageCost == 'Calculated') return 'Calculated';

          return $scope.currencySymbol + $filter('number')(item.postageCost, 2);
        }

        function handleNewListings (error, data, intialRound) {
          if(error) {
            if(error.finished) {
              $scope.requestErrorStatus = 'Waiting momentarily...';
            } else {
              $scope.requestErrorMessage = 'There was an problem connecting to eBay.';
              $scope.requestErrorStatus = 'Retry attempt: ' + error.onRetry;
            }
          } else if(data !== undefined && data.items !== undefined) {

            $scope.requestErrorMessage = false;
            $scope.currencySymbol = data.currency;

            /*
             * Save copy of the reverse of the scope listings so first is oldest,
             * this is done so array manipulations don't cause view to update 
             * unnecessarily. 
             */
            var listingItems = $scope.listingItems.slice(0);
            var pausedListingItems = $scope.pausedListingItems.slice(0);

            var numberOfNewItems = 0;
            var timeStamp = data.timestamp;

            listingItems.reverse();

            // Reverse the items in search result so that the latest items are added.
            data = data.items.reverse();

            if(data.length) {
              /*
               * loop through listings and if they exist in the existingItemIdReference then
               * ignore, if not then push to listingItems and add to cache.
               */
              _.each(data, function (item) {

                if(existingItemIdReference[item.itemID.toString()] === undefined) {
                  existingItemIdReference[item.itemID] = true;

                  // add a time received timestamp to the item
                  item.timeReceived = timeStamp;
                  item.momentInstance = moment();
                  item.clicked = false;
                  item.intial = !!intialRound;

                  // remove protocol so https warnings stop happening
                  if (item.galleryURL.indexOf(':') !== -1) {
                    item.galleryURL = item.galleryURL.split(':')[1];
                  }

                  if ($scope.isPaused) {
                    pausedListingItems.push(item);
                  } else {
                    // push item to listingItems array
                    listingItems.push(item);
                  }

                  // inc number of new items
                  numberOfNewItems++;
                }

              });
            }

            // if number of items is > 0
            if (numberOfNewItems) {

              // send notifications
              notificationService.notify(numberOfNewItems + ' new items listed!');
              soundNotificationService.notify();

              // cull old excess items to reduce system resources
              if ((listingItems.length + pausedListingItems.length) > 300) {

                for (var index = 299; index < listingItems.length; index++) {
                  var removedItem = listingItems.shift();
                  delete existingItemIdReference[removedItem.itemID.toString()];
                }

              }

            }

            // update the scoped listingItems with latest parsed listingItems array.
            listingItems.reverse();
            $scope.listingItems = listingItems;
            $scope.pausedListingItems = pausedListingItems;

            $scope.timeNow = moment();
          }
        }

        function returnToSearch () {
          requestService.stopQueryLoop();
          $location.path('/search');
        }

        function openItem (item, event) {
          event.stopImmediatePropagation();

          if(item.viewItemURL !== undefined) {
            if(!window.open(item.viewItemURL, '_BLANK')) {
              // TODO do something...
            } else {
              item.clicked = true;
            }
          }
        }

        function openItemSearchUrl (item, sold, event) {
          event.stopImmediatePropagation();

          var searchUrl = requestService.getSearchUrl($scope.searchGlobalId, $scope.searchCategoryId, $scope.searchCampaignId, item, sold);

          if(!window.open(searchUrl, '_BLANK')) {
            alert('Please enable popups');
          }
        }

        function showNotificationSettingsModal () {
          $modal({
            title: 'Notification Settings',
            placement: 'center',
            templateUrl: 'catcher/search/notification-settings-modal.partial.html',
            locals: {
              // searchCategory: $scope.formParams.selectedCategory,
              // searchLocation: $scope.formParams.searchLocation
            },
            controller: 'notificationSettingsModalController'
          });
        }

        function togglePausedListings () {
          if ($scope.isPaused) {
            if (!!$scope.pausedListingItems.length) {
              // Instantiate temp scope references to avoid unnecessary scope activity
              var tempListingItems = $scope.listingItems;
              var tempPausedListingItems = $scope.pausedListingItems;

              // Reverse them to deal with ordering
              tempListingItems.reverse();
              tempPausedListingItems.reverse();

              tempListingItems = tempListingItems.concat(tempPausedListingItems);
              tempListingItems.reverse();

              $scope.listingItems = tempListingItems;
              $scope.pausedListingItems = [];
            }

            // Unpause listing
            $scope.isPaused = false;
          } else {
            // Pause listing
            $scope.isPaused = true;
          }
        }

        function init () {
          if(angular.isUndefined(requestService.initialSearchResult)) {
            $location.path('/');
          }

          userService.isUserLoggedIn(function (error) {
            if(error) {
              errorService.delegateError(error);
            }
          });

          // parse initial search result
          if(angular.isDefined(requestService.initialSearchResult)) {
            handleNewListings(false, requestService.initialSearchResult);

            $scope.searchCategoryId = requestService.initialSearchResult.categoryId;
            $scope.searchGlobalId = requestService.initialSearchResult.globalId;
            $scope.searchCampaignId = requestService.initialSearchResult.campaignId;
          }

          // Stop listing page from being refreshed
          requestService.initialSearchResult = undefined;

          // Stop any started query loop
          requestService.stopQueryLoop();

          // Start new query loop
          requestService.runQueryLoop(handleNewListings);
        }
      }
    ]);

}(window.angular, window._, window.moment));
