; (function(angular) {

  'use strict';

  angular
    .module('catcherUtils')
    .directive('imageLoader', [
      function() {
        return {
          restrict: 'E',
          scope: {
            imageSrc: '@imageSrc',
            imageAlt: '@imageAlt'
          },
          templateUrl: 'catcher/utils/image-loader.directive.html',
          link: function(scope, element) {
            scope.loaded = false;
            // Errors on image src being blank
            scope.loadError = (scope.imageSrc === '');

            var loadedImage = element[0].querySelector('.loaded-image');

            loadedImage.onload = function() {
              scope.$apply(function() {
                scope.loaded = true;
              });
            };

            loadedImage.onerror = function() {
              scope.$apply(function() {
                scope.loadError = true;
              });
            };
          }
        };
      }
    ]);

}(window.angular));


