; (function(angular) {

  'use strict';

  angular
    .module('catcherNavBar')
    .controller('navBarController', [
      '$scope',
      '$location',
      '$rootScope',
      'userService',
      'requestService',
      'messageService',
      function($scope, $location, $rootScope, userService, requestService, messageService) {
        $scope.mobileHidden = false;
        $scope.curentRouteName = '';

        $rootScope.$on('$routeChangeSuccess', function () {
          if($location.path().length > 1) {
            $scope.curentRouteName = $location.path().slice(0);
          }
        });

        $scope.userService = userService;
        $scope.loginLogoutMenuItem = {
          title: 'Login',
          url: 'login',
          loggedIn: false,
          glyphClass: 'glyphicon-log-in'
        };

        $rootScope.$watch('currentUser', function(newVal) {
          if(newVal === undefined) {
            $scope.loginLogoutMenuItem = {
              title: 'Login',
              url: 'login',
              loggedIn: false,
              glyphClass: 'glyphicon-log-in'
            };
          } else {
            $scope.loginLogoutMenuItem = {
              title: 'Logout',
              url: 'logout',
              loggedIn: true,
              glyphClass: 'glyphicon-log-out'
            };
          }
        });

        $scope.$watch('curentRouteName', function (routeName) {
          switch (routeName) {
            case '/listing':
              // No point in checking for messages whilst searching.
              messageService.stopMessageCheckLoop();
              break;

            default:
              // Recheck for messages on all other routes.
              messageService.runMessageCheckLoop();

              // Ensure that we are not querying searches unless on the listing route.
              requestService.stopQueryLoop();
          }
        });
      }
    ]);

}(window.angular));
