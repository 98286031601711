; (function(angular, _) {

  'use strict';

  angular
    .module('catcherSearch')
    .directive('existingSearchFilterList', [
      function() {
        return {
          replace: true,
          scope: {
            filters: '=',
            globalId: '='
          },
          templateUrl: 'catcher/search/existing-search-filter-list.directive.html',
          link: function (scope) {

            scope.filterList = [];

            var getCurrencyFromGlobalID = function (globalId) {
              return {
                'EBAY-GB':    '£',
                'EBAY-US':    '$',
                'EBAY-AU':    '$',
                'EBAY-ENCA':  '$',
                'EBAY-MOTOR': '$',
                'EBAY-AT':    'EUR',
                'EBAY-FRBE':  'EUR',
                'EBAY-NLBE':  'EUR',
                'EBAY-CH':    'CHF',
                'EBAY-DE':    'EUR',
                'EBAY-ES':    'EUR',
                'EBAY-FR':    'EUR',
                'EBAY-IE':    'EUR',
                'EBAY-IT':    'EUR',
                'EBAY-NL':    'EUR',
                'EBAY-PL':    'zł'
              }[globalId];
            };

            var getSelectedCountryName = function (globalId) {
              return {
                'EBAY-GB':    'The UK',
                'EBAY-US':    'The USA',
                'EBAY-AT':    'Austria',
                'EBAY-AU':    'Australia',
                'EBAY-FRBE':  'Belgium',
                'EBAY-NLBE':  'Belgium',
                'EBAY-ENCA':  'Canada',
                'EBAY-MOTOR': 'The USA',
                'EBAY-CH':    'Switzerland',
                'EBAY-DE':    'Germany',
                'EBAY-ES':    'Spain',
                'EBAY-FR':    'France',
                'EBAY-IE':    'Ireland',
                'EBAY-IT':    'Italy',
                'EBAY-NL':    'The Netherlands',
                'EBAY-PL':    'Poland'
              }[globalId];
            };

            var filterRef = {
              min_price: function (filterItem) {
                return [
                  {
                    glyph: 'glyphicon-sort',
                    key:   'Min Price:',
                    value: getCurrencyFromGlobalID(scope.globalId) + parseInt(filterItem).toFixed(2)
                  }
                ];
              },

              max_price: function (filterItem) {
                return [
                  {
                    glyph: 'glyphicon-sort',
                    key:   'Max Price:',
                    value: getCurrencyFromGlobalID(scope.globalId) + parseInt(filterItem).toFixed(2)
                  }
                ];
              },

              delivery_type: function (filterItem) {
                return [
                  {
                    glyph: 'glyphicon-envelope',
                    key:   'Delivery Type:',
                    value: (filterItem === 'collect' ? 'Collect' : 'Post') + ' Only'
                  }
                ];
              },

              max_distance: function (filterItem) {
                return [
                  {
                    glyph: 'glyphicon-road',
                    key:   'Max Distance:',
                    value: filterItem + ' Miles'
                  }
                ];
              },

              free_shipping: function (filterItem) {
                return [
                  {
                    glyph: 'glyphicon-gift',
                    key:   'Free Shipping',
                    value: ''
                  }
                ];
              },

              domestic_seller_only: function (filterItem) {
                return [
                  {
                    glyph: 'glyphicon-globe',
                    key:   'Only Sellers Located in',
                    value: getSelectedCountryName(scope.globalId)
                  }
                ];
              },

              seller_type: function (filterItem) {
                return [
                  {
                    glyph: 'glyphicon-user',
                    key:   'Seller Type:',
                    value: filterItem[0].toUpperCase() + filterItem.slice(1)
                  }
                ];
              },

              condition: function (filterItemConditions) {
                return _.map(
                  filterItemConditions,
                  function (filterItemCondition) {
                    return {
                      glyph: 'glyphicon-wrench',
                      key:   'Condition:',
                      value: filterItemCondition
                    };
                  }
                );
              }
            };

            var getFilterList = function (filters) {
              return _.chain(filters)
                      .map(function (filter, filterKey) {
                        return filterRef[filterKey](filter);
                      })
                      .flatten()
                      .value();
            };

            scope.filterList = getFilterList(scope.filters);
          }
        };
      }
    ]);

}(window.angular, window._));
