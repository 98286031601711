; (function(angular) {

  'use strict';

  angular
    .module('catcherUtils')
    .directive('trailingDotAnimation', [
      '$timeout',
      function($timeout) {
        return {
          scope: {
            running: '='
          },
          template: '<span data-ng-repeat="ref in referenceArray track by $index">{{ (ref ? "." : "&nbsp;") }}</span>',
          link: function(scope, element) {

            scope.referenceArray = [false, false, false];

            var incrementAnimation = function () {
                $timeout(function () {
                  if (scope.referenceArray === [true, true, true]) {
                    scope.referenceArray = [false, false, false];
                  } else {
                    scope.referenceArray = [
                      true,
                      (scope.referenceArray[0] !== false ? true : false),
                      (scope.referenceArray[1] !== false ? true : false),
                    ];
                  }

                  if (scope.running) {
                    incrementAnimation();
                  }

                }, 750);
            };

            scope.$watch('running', function (newValue) {
              if (newValue) {
                incrementAnimation();
              }
            });
          }
        };
      }
    ]);

}(window.angular, window));

