; (function(angular) {

  'use strict';

  angular
    .module('catcherUserManagement')
    .controller('manageAccountController', [
      '$scope', 
      '$location', 
      'toastr', 
      '$routeParams',
      'userService', 
      'errorService', 
      function($scope, $location, toastr, $routeParams, userService, errorService) {

        userService.isUserLoggedIn(function(error) {

          if(error) {
            errorService.delegateError(error);
          } else {
            delegateRoute();
          }

        });

        $scope.saveWaiting = false;
        $scope.editing = 'none';

        $scope.formParams = {};
        $scope.successMessage = '';

        var delegateRoute = function() {

          resetFormParams();

          switch($routeParams.property) {
            case 'information':
              $scope.editInformation();
              break;
            case 'emailAddress':
              $scope.editEmailAddress();
              break;
            case 'name':
              $scope.editName();
              break;
            case 'location':
              $scope.editLocation();
              break;
            case 'password':
              $scope.editPassword();
              break;
            case 'postcode':
              $scope.editPostCode();
              break;
            default:
              $scope.cancelEditing();
              break;
          }
        };

        var resetFormParams = function() {
          $scope.formParams = {
            email:        $scope.currentUser.email, 
            name:       $scope.currentUser.name,
            location:     $scope.currentUser.location,
            password:     '',
            confirmPassword:  '',
            postcode:     $scope.currentUser.postcode
          };

          $scope.canMail = !!$scope.currentUser.canEmail;
        };

        $scope.toggleCanMail = function () {

          userService.updateAccount(
            {
              can_email: !$scope.canMail
            },
            function (error) {
              if(error) {
                toastr.error('Unable to update BinEye news preference, please try again');
              } else {
                $scope.canMail = !$scope.canMail;
                toastr.success('BinEye news preference updated successfully!');
              }
            }
            );
        };

        $scope.editingPropertyMessage = '';

        $scope.editInformation = function() {
          $scope.editing = 'information';
          $scope.editingPropertyMessage = 'Editing Personal Information';
        };

        $scope.editEmailAddress = function() {
          $scope.editing = 'email';
          $scope.editingPropertyMessage = 'Editing Email Address';
        };

        $scope.editName = function() {
          $scope.editing = 'name';
          $scope.editingPropertyMessage = 'Editing Name';
        };

        $scope.editLocation = function() {
          $scope.editing = 'location';
          $scope.editingPropertyMessage = 'Editing Location';
        };

        $scope.editPassword = function() {
          $scope.editing = 'password';
          $scope.editingPropertyMessage = 'Editing Password';
        };

        $scope.editPostCode = function() {
          $scope.editing = 'postcode';
          $scope.editingPropertyMessage = 'Editing Postcode';
        };

        $scope.downloadPersonalInformation = function () {
          userService.downloadPersonalInformation(
            function (error) {
              if(error) {
                toastr.error('Unable to download binEye personal information, please try again');
              }
            }
          );
        };

        $scope.deletePersonalInformation = function () {
          var confirmationMessage = 'Are you sure you want to delete your account and all information, this cannot be undone?';

          if(!window.confirm(confirmationMessage)) {
            return;
          }

          userService.deletePersonalInformation(
            function (error) {
              if(error) {
                toastr.error('Unable to delete binEye personal information, please try again');
              } else {
                window.location.reload();
              }
            }
          );
        };

        $scope.saveFormParams = function() {

          $scope.saveWaiting = true;

          var parsedFormParams = false;

          var alertParams = {};

          if($scope.editing === 'email') {

            if($scope.formParams.email !== '') {

              if($scope.formParams.password === $scope.formParams.confirmPassword) {

                parsedFormParams = {
                  email_address:    $scope.formParams.email,
                  password:     $scope.formParams.password
                };

              } else {
                toastr.error('Please check that both your passwords match.', 'Passwords do not match.');
              }

            } else {

              alertParams.title = 'Please enter an email address.';
              alertParams.content = 'Please check that you have entered your email address.';

            }

          } else if($scope.editing === 'name') {

            if($scope.formParams.name !== '') {

              parsedFormParams = {
                name: $scope.formParams.name
              };

            } else {
              toastr.error('Please enter a preferred name.', 'Please check your names.');
            }

          } else if($scope.editing === 'location') {

            if($scope.formParams.location !== '') {

              parsedFormParams = {
                location: $scope.formParams.location
              };

            } else {
              toastr.error('Please select your location.', 'Please check your location.');
            }

          } else if($scope.editing === 'postcode') {
            parsedFormParams = {
              postcode: $scope.formParams.postcode
            };
          } else if($scope.editing === 'password') {

            if(!$scope.formParams.password) {
              toastr.error('Please enter a password.', 'Passwords are invalid.');
            } else if($scope.formParams.password === $scope.formParams.confirmPassword) {
              parsedFormParams = {
                password: $scope.formParams.password
              };
            } else {
              toastr.error('Please check that both your passwords match.', 'Passwords do not match.');
            }

          }

          if(alertParams.title !== undefined && alertParams.content !== undefined) {
            toastr.error(alertParams.content, alertParams.title);
          }

          if(parsedFormParams !== false) {
            userService.updateAccount(
              parsedFormParams,
              function(error) {
                $scope.saveWaiting = false;
                if(error) {
                  toastr.error(error.text, 'Error');
                } else {
                  resetFormParams();
                  toastr.success($scope.editing.charAt(0).toUpperCase() + $scope.editing.slice(1) + ' updated successfully!');
                  $scope.editing = 'none';
                }
                $scope.go('/account/');
              }
            );
          } else {
            $scope.saveWaiting = false;
          }
        };

        $scope.cancelEditing = function() {
          $scope.editing = 'none';
          $scope.successMessage = '';
          resetFormParams();
        };

      }
    ]);

}(window.angular));
