; (function (angular) {

  'use strict';

  angular
    .module('catcherErrorManagement')
    .service('errorService', [
      '$rootScope',
      '$location',
      function($rootScope, $location) {

        this.currentError = undefined;

        this.MESSAGE_DOES_NOT_EXIST = {
          code: 601,
          text: 'The message being marked as read does not exist.'
        };

        this.INCORRECT_USER_REGISTRATION = {
          code: 501,
          text: 'Registration details are incorrect.'
        };

        this.USER_ALREADY_EXISTS = {
          code: 502,
          text: 'User with that email already exists.'
        };

        this.COULD_NOT_CONNECT = {
          code: 404,
          text: 'There was a problem connecting to the server, please check your connection and try again.'
        };

        this.COULD_NOT_CONNECT_TO_EBAY = {
          code: 405,
          text: 'There was a problem connecting to the ebay server, please try again shortly.'
        };

        this.NOT_LOGGED_IN = {
          code: 101,
          text: 'User not logged in.' 
        };

        this.INCORRECT_USER_LOGIN = {
          code: 102,
          text: 'Login credentials are incorrect.'  
        };

        this.INVALID_CATEGORY = {
          code: 200,
          text: 'Search category is invalid, please use a different category ID.'
        };

        this.SEARCH_NOT_VALID = {
          code: 201,
          text: 'Search is invalid.'
        };

        this.ERROR = {
          code: 202,
          text: 'Generic error.'
        };

        this.BAD_XSRF_TOKEN = {
          code: 203,
          text: 'Bad XSRF Token.'
        };

        this.INVALID_ACTIVATION = {
          code: 503,
          text: 'Invaid activation.'
        };

        this.handleLoadError = function() {
        };

        this.handleUserError = function() {
          $rootScope.go('/login');
        };

        this.handleXSRFError = function() {
          $rootScope.go('/login');
        };

        this.handleConnectionError = function(error) {
          errorService.gotoError(error);
        };

        this.gotoError = function(error) {
          $rootScope.currentError = error;
          $location.path('/error');
        };

        this.delegateError = function(error) {
          switch(error.code) {
            case 101:
              errorService.handleUserError(error);
              break;
            case 203:
              errorService.handleXSRFError(error);
              break;
            case 404:
            case 405:
              errorService.handleConnectionError(error);
              break;
            default:
              break;
          }
        };

        var errorService = this;
      }
    ]);

}(window.angular));


