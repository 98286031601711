; (function(angular) {

  'use strict';

  angular
    .module('catcherUserManagement')
    .controller('forgotPasswordController', [
      '$scope', 
      '$location', 
      'toastr', 
      '$routeParams', 
      'userService', 
      function($scope, $location, toastr, $routeParams, userService) {

        if($routeParams.resetPasswordToken !== undefined) {
          $scope.resetPasswordToken = $routeParams.resetPasswordToken;
          $scope.forgotPasswordStage = 2;
        } else {
          $scope.resetPasswordToken = '';
          $scope.forgotPasswordStage = 1;
        }

        $scope.resetWaiting = false;
        $scope.sendSuccess = false;
        $scope.resetSuccess = false;

        $scope.formParams = {};

        $scope.formParams.resetEmail = '';
        $scope.formParams.resetPassword = '';
        $scope.formParams.resetConfirmPassword = '';

        $scope.sendEmail = function() {

          var alertParams = {};

          if($scope.resetEmail !== '') {

            $scope.resetWaiting = true;

            userService.sendPasswordReset({
                'email_address': $scope.formParams.resetEmail
              },
              function(error) {

                if(error) {
                  alertParams.title = 'Invalid email';
                  alertParams.content = 'Please enter an email address';

                  toastr.error(alertParams.content, alertParams.title);
                } else {
                  $scope.resetEmail = '';
                  $scope.sendSuccess = true;
                }

                $scope.resetWaiting = false;
              }
            );

          } else {
            alertParams.title = 'Invalid email';
            alertParams.content = 'Please enter an email address';

            toastr.error(alertParams.content, alertParams.title);
          }

        };

        $scope.resetPassword = function() {

          var alertParams = {
            type: 'danger',
            container: '#alerts-container-two',
            duration: 5,
            animation: 'am-fade-and-slide-top',
            show: true
          };

          if(!$scope.resetPasswordToken) {
            $scope.redirectToForgotPassword();
          }

          if($scope.formParams.resetPassword !== '' && $scope.formParams.resetConfirmPassword !== '') {

            if($scope.formParams.resetPassword === $scope.formParams.resetConfirmPassword) {

              $scope.resetWaiting = true;

              userService.ResetPassword({
                'reset_token' : $scope.resetPasswordToken,
                'new_password'  : $scope.formParams.resetPassword
              },
              function(error) {

                $scope.resetWaiting = false;

                if(error) {
                  $scope.forgotPasswordStage = 3;
                } else {
                  $scope.resetSuccess = true;
                }

              });

            } else {

              alertParams.title = 'Invalid passwords';
              alertParams.content = 'Please ensure both password match.';

              toastr.error(alertParams.content, alertParams.title);

            }

          } else {

            alertParams.title = 'Invalid passwords';
            alertParams.content = 'Please ensure both password fields are filled out.';

            toastr.error(alertParams.content, alertParams.title);

          }

        };

        $scope.retryPasswordReset = function() {
          if(!!$scope.resetPasswordToken && $scope.formParams.resetPassword) {

              userService.ResetPassword({
                'reset_token' : $scope.resetPasswordToken,
                'new_password'  : $scope.formParams.resetPassword
              },
              function(error) {

                $scope.resetWaiting = false;

                if(error) {
                  $scope.forgotPasswordStage = 3;
                } else {
                  $scope.resetSuccess = true;
                }

              });

          } else {
            $scope.redirectToForgotPassword();
          }

        };

        $scope.redirectToForgotPassword = function() {
          $location.path('/forgot-password');
        };


        $scope.redirectToLogin = function() {
          $location.path('/login');
        };

      }
    ]);

}(window.angular));

