; (function(angular) {

  'use strict';

  angular
    .module('catcherErrorManagement')
    .controller('errorController', [
      '$scope',
      '$rootScope',
      '$location',
      function($scope, $rootScope, $location) {
        if($rootScope.currentError === undefined) {
          $location.path('/');
        }
      }
    ]);

}(window.angular));
