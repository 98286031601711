; (function (angular) {

  'use strict';

  angular
    .module('catcherUtils')
    .service('soundNotificationService', [
      function () {
        this.isEnabled    = false;
        this.audioElement = undefined;

        this.init = function (callback) {
          var srcAttribute,
              audioElement = document.createElement('audio');

          if (audioElement.canPlayType('audio/mpeg')) srcAttribute = '/public/audio/notification_sound.mp3';
          if (audioElement.canPlayType('audio/wav'))  srcAttribute = '/public/audio/notification_sound.wav';

          if (angular.isDefined(srcAttribute)) {
            audioElement.setAttribute('src', srcAttribute);

            soundNotificationService.audioElement = audioElement;
          }
        };

        this.toggle = function (callback) {
          soundNotificationService.isEnabled = !soundNotificationService.isEnabled;

          callback(soundNotificationService.isEnabled);
        };

        this.notify = function () {
          if (soundNotificationService.isEnabled === false) return;

          if (angular.isDefined(soundNotificationService.audioElement)) {
            soundNotificationService.audioElement.play();
          }
        };

        var soundNotificationService = this;

        soundNotificationService.init();
      }
    ]);

}(window.angular));
