; (function(angular, _) {

  'use strict';

  angular
    .module('catcherSearch')
    .controller('categorySelectionModalController', [
      '$scope',
      'requestService',
      'eventService',
      '$http',
      'searchCategory',
      'searchLocation',
      function($scope, requestService, eventService, $http, searchCategory, searchLocation) {
        var loadedCategories = {};

        $scope.searchLocation = searchLocation;
        $scope.isMotor = false;

        $scope.currentlySelected = buildModalSelectionState();

        $scope.toggleMotorCategories = toggleMotorCategories;
        $scope.selectCategory = selectCategory;
        $scope.submitCategory = submitCategory;
        $scope.getSelectedCategoryText = getSelectedCategoryText;

        $scope.changeSearchLocation = changeSearchLocation;
        $scope.getEquippedCategoryList = getEquippedCategoryList;

        equipCategoryList();

        function changeSearchLocation () {
          if (_.isString($scope.searchLocation)) {
            eventService.publish(
              eventService.CHANGE_SEARCH_LOCATION,
              $scope.searchLocation
            );

            equipCategoryList();
          }
        }

        function getEquippedCategoryList () {
          if (angular.isUndefined(loadedCategories[$scope.searchLocation])) {
            return [];
          }

          var categoryType = ($scope.isMotor ? 'motor-categories' : 'categories');

          return loadedCategories[$scope.searchLocation][categoryType];
        }

        function equipCategoryList () {
          $scope.currentlySelected = buildModalSelectionState();

          if (!getEquippedCategoryList().length) {
            requestCategoryList();
          }
        }

        function requestCategoryList () {
          searchLocation = $scope.searchLocation;

          loadedCategories[searchLocation] = {
            categories: [],
            motorCategories: []
          };

          $http.get('/public/categories/' + searchLocation + '-categories.json')
            .then(function(response) {
              var data = response.data;

              loadedCategories[searchLocation] = data;
            },
            function() {
              loadedCategories[searchLocation] = undefined;

              if (error) {
                // TODO Consolodate this!
                console.log('uh oh', error);
              }
            });
        }

        function toggleMotorCategories () {
          $scope.isMotor = !$scope.isMotor;
          equipCategoryList();
        }

        function selectCategory (parentCategory, childCategory, subChildCategory) {
          $scope.currentlySelected.parentCategory   = parentCategory;
          $scope.currentlySelected.childCategory    = childCategory;
          $scope.currentlySelected.subChildCategory = subChildCategory;

          _.first(document.querySelectorAll('#category-' + childCategory.code)).scrollIntoView(true);
        }

        function getSelectedCategoryText () {
          if ($scope.currentlySelected.manualCategoryID != '') {
            return 'Select category #' + $scope.currentlySelected.manualCategoryID;
          }

          return 'Select ' + $scope.currentlySelected.subChildCategory.title + ' category (#' + $scope.currentlySelected.subChildCategory.code + ')';
        }

        function submitCategory () {
          var currentlySelected = $scope.currentlySelected;

          var selectedCategory = currentlySelected.subChildCategory.code;

          // If category manually entered, always go with that.
          if (!!currentlySelected.manualCategoryID) {
            selectedCategory = currentlySelected.manualCategoryID;
          }

          eventService.publish(eventService.CHANGE_SEARCH_CATEGORY, selectedCategory);
        }

        function buildModalSelectionState () {
          return {
            parentCategory: {
              title: '',
              code: ''
            },
            childCategory: {
              title: '',
              code: ''
            },
            subChildCategory: {
              title: '',
              code: ''
            },
            manualCategoryID: ''
          };
        }
      }
    ]);

}(window.angular, window._));

