; (function(angular) {

  'use strict';

  angular
    .module('catcherSearch')
    .service('requestService', [
      '$http',
      'errorService',
      '$interval',
      function($http, errorService, $interval) {

        this.initialSearchResult = undefined;
        this.intervalID = undefined;

        this.getSearchUrl = function(globalId, categoryId, campaignId, item, sold) {

          // Ensure globalId is globalIdTLDRef key friendly
          globalId = globalId.toLowerCase();

          // Ref globalId to TLD
          var globalIdTLDRef = {
            'ebay-at':   'ebay.at',
            'ebay-au':   'ebay.com.au',
            'ebay-frbe': 'benl.ebay.be',
            'ebay-nlbe': 'befr.ebay.be',
            'ebay-enca': 'ebay.ca',
            'ebay-ch':   'ebay.ch',
            'ebay-de':   'ebay.de',
            'ebay-es':   'ebay.es',
            'ebay-fr':   'ebay.fr',
            'ebay-ie':   'ebay.ie',
            'ebay-it':   'ebay.it',
            'ebay-nl':   'ebay.nl',
            'ebay-pl':   'ebay.pl',
            'ebay-gb':   'ebay.co.uk',
            'ebay-us':   'ebay.com'
          };

          // Get the base url
          var baseUrl = 'https://www.' + globalIdTLDRef[globalId] + '/sch/i.html';

          var compiledSearchUrl = baseUrl + '?';

          var keywords = encodeURI(item.title).replace('&', '%26');

          var argArray = [
            'LH_BIN=1',
            'LH_CAds=1',
            ('_nkw=' + keywords)
          ];

          if (sold) {
            // Show only sold listings
            argArray.push('LH_Sold=1');
            argArray.push('LH_Complete=1');
            argArray.push('rt=nc');
            // Sort order Price Highest first
            argArray.push('_sop=16');
          } else {
            // Sort order Price Lowest first
            argArray.push('_sop=15');
          }

          // If the item's CatID can be used (being the same
          // country as the base search), use it. If not use
          // the same CatID as the general search. Worst comes
          // to worst, dont include a CatID.
          if (item.globalId === globalId) {
            categoryId = item.categoryId;

            if (angular.isArray(categoryId) && categoryId.length > 0) {
              categoryId = item.categoryId[0];
            }
          }

          if (!!categoryId) {
            argArray.push('_sacat=' + categoryId);
          }

          // Compile URL by adding args delimetered by '&', and URL encode
          // compiledSearchUrl = encodeURI(compiledSearchUrl + argArray.join('&'));
          compiledSearchUrl = compiledSearchUrl + argArray.join('&');

          return compiledSearchUrl;
        };

        this.runQueryLoop = function(callback) {
          if(angular.isDefined(requestService.intervalID)) return;

          requestService.intervalID = setInterval(
            function() {
              requestService.runSearch(
                50,
                function(error, data) {
                  callback(error, data);
                }
              );
            },
            10000
          );
        };

        this.stopQueryLoop = function() {
          if(angular.isDefined(requestService.intervalID)) {
            clearInterval(requestService.intervalID);

            requestService.intervalID = undefined;
          }
        };

        this.registerSearch = function(keywords, category, globalId, isMotoringCategory, filters, callback) {

          var requestParameters = {};

          if(keywords !== '' && keywords !== undefined) {
            requestParameters.keywords = keywords;
          }

          if(category !== '' && category !== undefined) {
            requestParameters.category_id = category;
          }

          if(filters !== undefined) {
            requestParameters = angular.extend(requestParameters, filters);
          }

          if(globalId !== '' && globalId !== undefined) {
            requestParameters.global_id = globalId;
          }

          if(isMotoringCategory) {
            requestParameters.is_motoring_category = 1;
          }

          $http.post(
            '/ajax/registerSearch',
            requestParameters
          ).then(function(response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(
                errorService.ERROR,
                ''
              );
            } else if(data.error === 'COULD_NOT_CONNECT_TO_EBAY') {
              callback(
                errorService.COULD_NOT_CONNECT_TO_EBAY,
                ''
              );
            } else if(data.error === 'SEARCH_NOT_VALID') {
              callback(
                errorService.SEARCH_NOT_VALID,
                ''
              );
            } else if(data.error === 'INVALID_CATEGORY') {
              callback(
                errorService.INVALID_CATEGORY,
                ''
              );
            } else if(data.error === 'ERROR') {

              if(data.details.message !== undefined && data.details.severity !== undefined) {
                callback(
                  {
                    code: 203,
                    text: data.details.message
                  },
                  ''
                );
              } else {
                callback(
                  errorService.ERROR,
                  ''
                );
              }

            } else {

              callback(
                false,
                data
              );
            }

          },
          function() {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.runSearch = function(numberOfItems, callback) {
          var requestParameters = {};

          if(numberOfItems !== '' && numberOfItems !== undefined) {
            requestParameters.number_of_items = numberOfItems;
          }

          $http.post(
            '/ajax/runSearch',
            requestParameters
          ).then(function(response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(
                errorService.ERROR,
                ''
              );
            } else if(data.error === 'COULD_NOT_CONNECT_TO_EBAY') {
              callback(
                errorService.COULD_NOT_CONNECT_TO_EBAY,
                ''
              );
            } else if(data.error === 'SEARCH_NOT_VALID') {
              callback(
                errorService.SEARCH_NOT_VALID,
                ''
              );
            } else if(data.error === 'ERROR') {
              callback(
                errorService.ERROR,
                ''
              );
            } else {
              callback(false, data.details);
            }
          },
          function() {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.selectSearch = function(searchId, callback) {
          var requestParameters = {};

          if(searchId !== '' && searchId !== undefined) {
            requestParameters.search_id = searchId;
          }

          $http.post(
            '/ajax/selectSearch',
            requestParameters
          ).then(function(response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(
                errorService.ERROR,
                ''
              );
            } else if(data.error === 'COULD_NOT_CONNECT_TO_EBAY') {
              callback(
                errorService.COULD_NOT_CONNECT_TO_EBAY,
                ''
              );
            } else if(data.error === 'SEARCH_NOT_VALID' || data.error === 'INVALID_SEARCH') {
              callback(
                errorService.SEARCH_NOT_VALID,
                ''
              );
            } else if(data.error === 'ERROR') {
              callback(
                errorService.ERROR,
                ''
              );
            } else {
              callback(false, data);
            }
          },
          function() {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.getMostRecentSearches = function(callback) {
          $http.post(
            '/ajax/getMostRecentSearches',
            {}
          ).then(function(response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(
                errorService.ERROR,
                ''
              );
            } else {
              callback(
                data.error,
                data.details
              );
            }

          },
          function() {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        var requestService = this;
      }
    ]);

}(window.angular));
