; (function(angular) {

  'use strict';

  angular.module('catcherSearch')
    .directive('advancedSearchFilters', [
      'userService',
      'eventService',
      'toastr',
      function(userService, eventService, toastr) {
        return {
          replace: true,
          templateUrl: 'catcher/search/advanced-search-filters.directive.html',
          link: function(scope, element) {

            scope.toggleShowAdvancedSearchPanel = function () {
              scope.advancedSearchPanel.showPanel = !scope.advancedSearchPanel.showPanel;
            };

            scope.togglePriceRangeFilters = function (hide) {
              scope.advancedSearchPanel.showPriceRangeFilters = !hide;

              if (hide) {
                scope.formParams.filters.priceLow = undefined;
                scope.formParams.filters.priceHigh = undefined;
              }
            };

            scope.toggleDeliveryFilters = function (hide) {
              scope.advancedSearchPanel.showDeliveryFilters = !hide;

              scope.formParams.filters.postcode = scope.currentUser.postcode;

              if (hide) {
                scope.formParams.filters.deliveryType = 'all';
                scope.formParams.filters.maxDistance = undefined;
                scope.formParams.filters.freePostage = false;
              }
            };

            scope.toggleSellerFilters = function (hide) {
              if (!hide) {
                scope.advancedSearchPanel.showSellerFilters = true;
              } else if (scope.isSellerFiltersDefault()) {
                scope.advancedSearchPanel.showSellerFilters = false;
              }
            };

            scope.isSellerFiltersDefault = function () {
              return (
                scope.formParams.filters.sellerType         === 'all' &&
                scope.formParams.filters.domesticSellerOnly === false
              );
            };

            scope.clearSellerTypeFilters = function () {
              scope.formParams.filters.sellerType = 'all';

              scope.toggleSellerFilters(true);
            };

            scope.clearDomesticSellerFilters = function () {
              scope.formParams.filters.domesticSellerOnly = false;

              scope.toggleSellerFilters(true);
            };

            scope.toggleConditionFilters = function (hide) {
              scope.advancedSearchPanel.showConditionFilters = !hide;

              if (hide) {
                scope.formParams.filters.condition = 'all';
              }
            };

            scope.getSelectedCurrency = function () {
              return {
                'EBAY-GB':    '£',
                'EBAY-US':    '$',
                'EBAY-AU':    '$',
                'EBAY-ENCA':  '$',
                'EBAY-MOTOR': '$',
                'EBAY-AT':    'EUR',
                'EBAY-FRBE':  'EUR',
                'EBAY-NLBE':  'EUR',
                'EBAY-CH':    'CHF',
                'EBAY-DE':    'EUR',
                'EBAY-ES':    'EUR',
                'EBAY-FR':    'EUR',
                'EBAY-IE':    'EUR',
                'EBAY-IT':    'EUR',
                'EBAY-NL':    'EUR',
                'EBAY-PL':    'zł'
              }[scope.formParams.searchLocation];
            };

            scope.getSelectedCountryName = function () {
              return {
                'EBAY-GB':    'The UK',
                'EBAY-US':    'The USA',
                'EBAY-AT':    'Austria',
                'EBAY-AU':    'Australia',
                'EBAY-FRBE':  'Belgium',
                'EBAY-NLBE':  'Belgium',
                'EBAY-ENCA':  'Canada',
                'EBAY-MOTOR': 'The USA',
                'EBAY-CH':    'Switzerland',
                'EBAY-DE':    'Germany',
                'EBAY-ES':    'Spain',
                'EBAY-FR':    'France',
                'EBAY-IE':    'Ireland',
                'EBAY-IT':    'Italy',
                'EBAY-NL':    'The Netherlands',
                'EBAY-PL':    'Poland'
              }[scope.formParams.searchLocation];
            };

            scope.isDisabled = function (string) {
              return (string !== 0 && !string);
            };

            scope.isDisabledNumeric = function (string) {
              return (string !== 0 && !string);
            };

            scope.showSellerTypeFilters = function () {
              return !!{
                'EBAY-AT': true,
                'EBAY-BE': true,
                'EBAY-CH': true,
                'EBAY-DE': true,
                'EBAY-ES': true,
                'EBAY-FR': true,
                'EBAY-GB': true,
                'EBAY-IE': true,
                'EBAY-IT': true,
                'EBAY-PL': true
              }[scope.formParams.searchLocation];
            };

            scope.showGranularRefurbishedConditions = function () {
              return (
                scope.isGranularRefurbishedConditionCategorySelected() &&
                scope.isGranularRefurbishedConditionLocationSelected()
              );
            };

            scope.savePostCode = function (postcode) {
              userService.updateAccount(
                {
                  postcode: postcode
                },
                function(error) {
                  if(error) {
                    toastr.error(error.text, 'Error');
                  } else {
                    toastr.success('Postcode saved successfully!');
                  }
                }
              );
            };

            scope.isGranularRefurbishedConditionCategorySelected = function () {
              return scope.formParams.selectedCategory === 9355;
            };

            scope.isGranularRefurbishedConditionLocationSelected = function () {
              return _.include(
                [
                  'EBAY-GB',
                  'EBAY-US',
                  'EBAY-ENCA',
                  'EBAY-DE',
                  'EBAY-AU'
                ],
                scope.formParams.searchLocation
              );
            };

            scope.isGranularRefurbishedConditionSelected = function () {
              return _.include(
                [
                  'excellent_refurbished',
                  'very_good_refurbished',
                  'good_refurbished'
                ],
                scope.formParams.filters.condition
              );
            };

            scope.isNonGranularRefurbishedConditionSelected = function () {
              return scope.formParams.filters.condition === 'seller_refurbished';
            };

            scope.selectNonGranularRefurbishedCondition = function () {
              scope.formParams.filters.condition = 'seller_refurbished';
            };

            scope.selectDefaultCondition = function () {
              scope.formParams.filters.condition = 'all';
            };

            scope.handleGranularConditionCategoryLocationUpdated = function () {
              if (
                scope.isGranularRefurbishedConditionCategorySelected() &&
                scope.isGranularRefurbishedConditionLocationSelected()
              ) {
                if (scope.isNonGranularRefurbishedConditionSelected()) {
                  scope.selectDefaultCondition();

                  return;
                }
              }

              if (scope.isGranularRefurbishedConditionSelected()) {
                if (
                  scope.isGranularRefurbishedConditionCategorySelected() &&
                  scope.isGranularRefurbishedConditionLocationSelected()
                ) {
                  return;
                }

                scope.selectNonGranularRefurbishedCondition();
              }
            };

            eventService.subscribe(eventService.CHANGE_SEARCH_LOCATION, function (searchLocation) {
              if (_.isString(searchLocation)) {
                scope.handleGranularConditionCategoryLocationUpdated();
              }
            });

            eventService.subscribe(eventService.CHANGE_SEARCH_CATEGORY, function (searchCategory) {
              searchCategory = parseInt(searchCategory);

              if (!_.isNaN(searchCategory)) {
                scope.handleGranularConditionCategoryLocationUpdated();
              }
            });

            scope.selectedConditions = {
              new:                      false,
              notnew:                   false,
              new_other:                false,
              new_with_defects:         false,
              certified_refurbished:    false,
              excellent_refurbished:    false,
              very_good_refurbished:    false,
              good_refurbished:         false,
              seller_refurbished:       false,
              like_new:                 false,
              used:                     false,
              very_good:                false,
              good:                     false,
              acceptable:               false,
              for_parts_or_not_working: false,
              all:                      true
            };

            scope.selectCondition = function (conditionKey) {
              if (conditionKey === 'all') {
                _.each(
                  Object.keys(scope.selectedConditions),
                  function (selectedConditionKey) {
                    scope.selectedConditions[selectedConditionKey] = (selectedConditionKey === 'all');
                  }
                );
              } else {
                scope.selectedConditions.all = false;
                scope.selectedConditions[conditionKey] = !scope.selectedConditions[conditionKey];
              }

              // If no condition is selected, select 'all'
              if (_.chain(scope.selectedConditions).values().include(true).value() === false) {
                scope.selectedConditions.all = true;
              }
            };

            scope.$watch(
              'selectedConditions',
              function (updatedSelectedConditions) {
                var conditionFilterParam = [];

                _.each(
                  Object.keys(updatedSelectedConditions),
                  function (selectedConditionKey) {
                    if (updatedSelectedConditions[selectedConditionKey]) {
                      conditionFilterParam.push(selectedConditionKey);
                    }
                  }
                );

                scope.formParams.filters.condition = conditionFilterParam.join(',');
              },
              true
            );

          }
        };
      }
    ]);

}(window.angular));
