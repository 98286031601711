; (function(angular, _) {

  'use strict';

  angular
    .module('catcherMessaging')
    .service('messageService', [
      '$http', 
      'requestService', 
      'errorService', 
      '$rootScope', 
      '$interval',
      'toastr',
      function($http, requestService, errorService, $rootScope, $interval, toastr) {
        this.intervalPromise = undefined;

        this.runMessageCheckLoop = function() {
          var handleMessageResponse = function(error, data) {
            if(error)                        return;
            if(data === $rootScope.messages) return;

            $rootScope.messages = data;
            messageService.updateUnreadMessagesFlag(data);
          };

          var handleIntervalTick = function () {
            messageService.getAllMessages(handleMessageResponse);
          };

          if(angular.isDefined(messageService.intervalPromise)) return;

          messageService.intervalPromise = $interval(handleIntervalTick, 180000);
        };

        this.stopMessageCheckLoop = function() {
          if(messageService.intervalPromise === undefined) return;

          $interval.cancel(messageService.intervalPromise);

          messageService.intervalPromise = undefined;
        };

        this.updateUnreadMessagesFlag = function(messages, noToast) {
          $rootScope.unreadMessages = false;
          var unreadMessages = 0;

          _.each(
            messages,
            function(message) {
              if(message.contains_unread) {
                $rootScope.unreadMessages = true;
                unreadMessages++;
              }
            }
          );

          if(unreadMessages === 0) {
            $rootScope.unreadMessages = false;
          }

          if(unreadMessages && !noToast) {
            toastr.info('You have ' + unreadMessages + ' new support message' + (unreadMessages > 1 ? 's' : '') + '!');
          }
        };

        this.getAllMessages = function(callback) {
          $http.post(
            '/ajax/getAllMessages',
            {}
          ).then(function(response) {
            var data = response.data;

            if(data.error === 'NOT_LOGGED_IN') {
              callback(errorService.NOT_LOGGED_IN, '');
            } else {
              callback(false, data.details);
            }
          },
          function() {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.sendMessage = function(subject, body, isReply, callback) {
          var postFields = {
            subject: subject,
            body: body,
          };

          if(isReply !== false) {
            postFields.is_reply = isReply;
          }

          $http.post(
            '/ajax/sendMessage',
            postFields
          ).then(function(response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(errorService.ERROR, '');
            } else if(data.error === 'NOT_LOGGED_IN') {
              callback(errorService.NOT_LOGGED_IN, '');
            } else {
              callback(false, data.details);
            }
          },
          function() {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.markMessageAsRead = function(messageId, callback) {

          $http.post(
            '/ajax/markMessageAsRead',
            {
              message_id: messageId
            }
          ).then(function(response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(
                errorService.ERROR,
                ''
              );
            } else if(data.error === 'NOT_LOGGED_IN') {
              callback(errorService.NOT_LOGGED_IN, '');
            } else if(data.error === 'MESSAGE_DOES_NOT_EXIST') {
              callback(errorService.MESSAGE_DOES_NOT_EXIST, '');
            } else {
              messageService.getAllMessages(messageService.updateUnreadMessagesFlag);

              callback(false, data.details);
            }
          },
          function() {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        var messageService = this;
      }
    ]);

}(window.angular, window._));
