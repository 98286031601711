; (function(angular) {

  'use strict';

  angular
    .module('catcherUtils')
    .service('eventService', [
      '$rootScope',
      function ($rootScope) {

        this.CHANGE_SEARCH_LOCATION = 'CHANGE_SEARCH_LOCATION';
        this.CHANGE_SEARCH_CATEGORY = 'CHANGE_SEARCH_CATEGORY';

        this.publish = publishEvent;
        this.subscribe = subscribeToEvent;

        function publishEvent (eventName, eventProperties) {
          $rootScope.$broadcast(eventName, eventProperties);
        }

        function subscribeToEvent (eventName, callback) {
          $rootScope.$on(eventName, function (eventTriggered, eventData) {
            callback(eventData, eventTriggered);
          });
        }
      }
    ]);

}(window.angular));

