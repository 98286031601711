; (function(angular, _) {

  'use strict';

  angular
    .module('catcherUtils')
    .directive('timeAgo', [
      function() {
        return {
          scope: {
            timeNow: '=',
            timeThen: '=',
            prefix: '@'
          },
          template: '{{ prefix + " " + timeAgo + "." }}',
          link: function(scope, element) {
            scope.$watch(
              'timeNow',
              _.memoize(
                function (newTime) {
                  scope.timeAgo = scope.timeThen.fromNow();
                }
              )
            );
          }
        };
      }
    ]);

}(window.angular, window._));
