; (function(angular) {

  'use strict';

  angular.module('catcherSearch')
    .directive('activateUserAlert', [
      'userService',
      'toastr',
      '$location',
      '$rootScope',
      function(userService, toastr, $location, $rootScope) {
        return {
          replace: true,
          templateUrl: 'catcher/user_management/activate-user-alert.directive.html',
          link: function(scope, element) {

            scope.showActivationAlert = function () {
              return $rootScope.currentUser && $rootScope.currentUser.zoneId !== '1';
            };

            scope.currentUserName = function () {
              return $rootScope.currentUser && $rootScope.currentUser.name;
            };

            scope.resendActivationLink = function() {

              userService.resendActivationEmail(function(error) {
                if(error) {
                  if(error.code == 101) {
                    $location.path('/login');
                  } else if(error.code == 404) {
                    toastr.error('Please try again', 'Could not connect.');
                  }
                } else {
                  toastr.success('Welcome email successfully resent.');
                }
              });
            };

          }
        };
      }
    ]);

}(window.angular));
