; (function(angular) {

  'use strict';

  angular
    .module('catcherUserManagement')
    .config(
      [
        '$routeProvider',
        function($routeProvider) {
          $routeProvider
            .when('/login', {
              templateUrl: 'catcher/user_management/login.partial.html',
              controller: 'loginController'
            })
            .when('/logout', {
              templateUrl: 'catcher/user_management/logout.partial.html',
              controller: 'logoutController'
            })
            .when('/register', {
              templateUrl: 'catcher/user_management/registration.partial.html',
              controller: 'registrationController'
            })
            .when('/register/:activationToken', {
              templateUrl: 'catcher/user_management/registration.partial.html',
              controller: 'registrationController'
            })
            .when('/forgot-password', {
              templateUrl: 'catcher/user_management/forgot-password.partial.html',
              controller: 'forgotPasswordController'
            })
            .when('/forgot-password/:resetPasswordToken', {
              templateUrl: 'catcher/user_management/forgot-password.partial.html',
              controller: 'forgotPasswordController'
            })
            .when('/account', {
              templateUrl: 'catcher/user_management/manage-account.partial.html',
              controller: 'manageAccountController'
            })
            .when('/account/:property', {
              templateUrl: 'catcher/user_management/manage-account.partial.html',
              controller: 'manageAccountController'
            });
        }
      ]
    );

}(window.angular));
