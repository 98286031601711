; (function(angular) {

  'use strict';

  angular
    .module('catcherUserManagement')
    .controller('logoutController', [
      '$scope',
      '$location',
      'userService',
      'requestService',
      'errorService',
      function($scope, $location, userService, requestService, errorService) {

        $scope.logoutWaiting = true;

        userService.isUserLoggedIn(function(error) {
          if(error) {
            errorService.delegateError(error);
          } else {
            userService.logout(function() {
              $scope.logoutWaiting = false; 
            });
          }
        });

        requestService.stopQueryLoop();

      }
    ]);

}(window.angular));
