; (function(angular) {

  'use strict';

  angular
    .module('catcherMessaging')
    .config(
      [
        '$routeProvider',
        function($routeProvider) {
          $routeProvider
            .when('/support', {
              templateUrl: 'catcher/messaging/support.partial.html',
              controller: 'supportController'
            })
            .when('/support/:messageId', {
              templateUrl: 'catcher/messaging/support.partial.html',
              controller: 'supportController'
            });
        }
      ]
    );

}(window.angular));
