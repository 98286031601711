; (function (angular, Notification) {

  'use strict';

  angular
    .module('catcherUtils')
    .service('notificationService', [
      function () {
        this.isEnabled                      = false;
        this.doesBrowserSupportNotification = !!Notification;
        this.previousNotification           = undefined;

        this.requestPermission = function (callback) {
          if(!notificationService.doesBrowserSupportNotification) return;
          if(notificationService.isNotificationsAllowed())        return;

          Notification.requestPermission(function (newPermission) {
            if(callback !== undefined) {
              callback(newPermission);
            }
          });
        };

        this.enable = function (callback) {
          if (notificationService.isNotificationsAllowed()) {
            notificationService.isEnabled = true;
          } else {
            notificationService.requestPermission(function (permission) {
              notificationService.isEnabled = (permission === 'granted');
            });
          }

          callback(notificationService.isEnabled);
        };

        this.disable = function (callback) {
          notificationService.isEnabled = false;

          callback(notificationService.isEnabled);
        };

        this.toggle = function (callback) {
          if (notificationService.isEnabled) {
            this.disable(callback);
          } else {
            this.enable(callback);
          }
        };

        this.notify = function (text) {

          if (notificationService.isEnabled === false) return;

          if(
            notificationService.doesBrowserSupportNotification &&
            notificationService.isNotificationsAllowed
          ) {

            if(notificationService.previousNotification !== undefined) {
              notificationService.previousNotification.close();
              notificationService.previousNotification = undefined;
            }

            var notification = new Notification(
              'BinEye Notification',
              {
                body: text,
                tag: 'BinEye'
              }
            );

            notification.onclose = function () {
              notification.previousNotification = undefined;
            };

            notificationService.previousNotification = notification;
          } else {
            notificationService.requestPermission();
          }
        };

        this.isNotificationsAllowed = function () {
          return (
            (Notification.permission === 'granted') &&
            (notificationService.doesBrowserSupportNotification)
          );
        };

        var notificationService = this;
      }
    ]);

}(window.angular, window.Notification));
