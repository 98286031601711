; (function(angular) {

  'use strict';

  angular
    .module('catcherUserManagement')
    .controller('loginController', [
      '$scope',
      '$location',
      'toastr',
      'userService',
      'requestService',
      'errorService',
      function($scope, $location, toastr, userService, requestService, errorService) {

        $scope.loginWaiting = true;

        userService.isUserLoggedIn(function(error) {
          if(error) {
            errorService.delegateError(error);
            $scope.loginWaiting = false;
          } else {
            $location.path('/search');
          }
        });

        requestService.stopQueryLoop();

        $scope.formParams = {};

        $scope.processFormParams = function() {

          $scope.formParams.rememberMe = (!!$scope.formParams.rememberMe);

          if(
            $scope.formParams.email !== undefined &&
            $scope.formParams.email !== '' &&
            $scope.formParams.password !== undefined &&
            $scope.formParams.password !== ''
          ) {

            $scope.loginWaiting = true;

            userService.login(

              $scope.formParams.email,
              $scope.formParams.password,
              $scope.formParams.rememberMe,

              function(error, user) {

                if(error && error.code == 202) {

                  toastr.error('Please try again later.', 'There was an error!');

                } else if(error && error.code == 102) {

                  toastr.error('Incorrect login credentials!', 'Please check your login credentials.');

                } else if(error && error.code == 404) {

                  toastr.error('Please check your internet connection and try again.', 'Could not connect to server.');

                } else {

                  toastr.success('Welcome ' + user.name, 'Login Success');

                  $location.path('/');

                }

                $scope.loginWaiting = false;

              }
            );
          }
        };

        $scope.redirectToRegistration = function() {
          $location.path('/register');
        };

      }
    ]);

}(window.angular));
