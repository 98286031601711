; (function(angular, _) {

  'use strict';

  angular
    .module('catcherSearch')
    .controller('searchController', [
      '$scope',
      '$location',
      '$alert',
      '$modal',
      'toastr',
      'userService',
      'requestService',
      'errorService',
      'eventService',
      function($scope, $location, $alert, $modal, toastr, userService, requestService, errorService, eventService) {

        $scope.advancedSearchPanel = {
          showPanel: false,
          showPriceRangeFilters: false,
          showDeliveryFilters: false,
          showSellerFilters: false
        };

        $scope.formParams = buildFormParams();

        $scope.randomTip = getRandomTip();

        $scope.onEnterSubmitSearch = onEnterSubmitSearch;
        $scope.submitSearch = submitSearch;
        $scope.compileFiltersWorkflow = compileFiltersWorkflow;
        $scope.isFiltersInvalid = isFiltersInvalid;
        $scope.gotoAccountActivation = gotoAccountActivation;
        $scope.showCategorySelectionModal = showCategorySelectionModal;
        $scope.getCategorySelectionModalButtonText = getCategorySelectionModalButtonText;

        $scope.searchWating = false;

        $scope.previousSearches = [];

        init();

        eventService.subscribe(eventService.CHANGE_SEARCH_LOCATION, function (searchLocation) {
          if (_.isString(searchLocation)) {
            $scope.formParams.searchLocation = searchLocation;
          }
        });

        eventService.subscribe(eventService.CHANGE_SEARCH_CATEGORY, function (searchCategory) {
          searchCategory = parseInt(searchCategory);

          if (_.isNaN(searchCategory)) {
            toastr.error('Category IDs do not contain any letters or special characters.', 'Invalid category');
          } else {
            $scope.formParams.selectedCategory = searchCategory;
          }
        });

        function showCategorySelectionModal () {
          $modal({
            title: 'Select Categories',
            placement: 'center',
            templateUrl: 'catcher/search/category-selection-modal.partial.html',
            locals: {
              searchCategory: $scope.formParams.selectedCategory,
              searchLocation: $scope.formParams.searchLocation
            },
            controller: 'categorySelectionModalController'
          });
        }

        function getCategorySelectionModalButtonText () {
          var categoryText = $scope.formParams.selectedCategory;

          // If category is empty, just say All instead
          if (!categoryText) {
            categoryText = 'All';
          }

          return 'Category: ' + categoryText;
        }

        function onEnterSubmitSearch (event) {
          // On press enter - submit search
          if (event.which === 13) {
            $scope.submitSearch();
          }
        }

        function submitSearch (search) {

          if(search !== undefined) {

            if(search.id !== undefined) {

              $scope.searchWating = true;

              requestService.selectSearch(search.id, function(error, data) {
                if(error) {
                  $scope.searchWating = false;
                  toastr.error(error.text, 'Could not connect!');
                } else {
                  requestService.initialSearchResult = data.details;
                  $location.path('/listing');
                }
              });
            }

          } else {

            var filterErrors = $scope.isFiltersInvalid();
            if(filterErrors) {
              _.each(filterErrors, function (filterError) {
                toastr.error(filterError.content, filterError.title);
              });
              return;
            }

            if($scope.formParams.selectedCategory === '' && $scope.formParams.keywords.replace('&', '').replace(' ', '') === '') {
              toastr.error('Please either select a keyword or a category or both.', 'Incorrect search parameters!');
            }
            else
            {

              $scope.searchWating = true;

              requestService.registerSearch(
                $scope.formParams.keywords,
                $scope.formParams.selectedCategory,
                $scope.formParams.searchLocation,
                false,
                $scope.compileFiltersWorkflow(),
                function(error, data) {

                  if(error) {
                    $scope.searchWating = false;
                    toastr.error(error.text, 'Search failed!');
                  } else {
                    requestService.initialSearchResult = data.details;
                    $location.path('/listing');
                  }

                }
              );
            }
          }
        }

        function compileFiltersWorkflow () {
          var compiledFilters = {};

          if (angular.isDefined($scope.formParams.filters.priceLow)) {
            compiledFilters.price_low = $scope.formParams.filters.priceLow;
          }

          if (angular.isDefined($scope.formParams.filters.priceHigh)) {
            compiledFilters.price_high = $scope.formParams.filters.priceHigh;
          }

          if ($scope.formParams.filters.deliveryType == 'collect') {
            compiledFilters.delivery_type = $scope.formParams.filters.deliveryType;
            compiledFilters.max_distance = $scope.formParams.filters.maxDistance;
            compiledFilters.postcode = $scope.formParams.filters.postcode;
          } else {
            compiledFilters.delivery_type = $scope.formParams.filters.deliveryType;
            compiledFilters.free_postage = $scope.formParams.filters.freePostage;
          }

          if (angular.isDefined($scope.formParams.filters.sellerType) && ($scope.formParams.searchLocation === 'EBAY-GB')) {
            compiledFilters.seller_type = $scope.formParams.filters.sellerType;
          }

          if (angular.isDefined($scope.formParams.filters.domesticSellerOnly)) {
            compiledFilters.domestic_seller_only = $scope.formParams.filters.domesticSellerOnly;
          }

          if (angular.isDefined($scope.formParams.filters.condition)) {
            compiledFilters.condition = $scope.formParams.filters.condition;
          }

          return compiledFilters;
        }

        function isFiltersInvalid () {
          var isValidFloat = function (number) {
            return !isNaN(parseFloat(number));
          };

          var errorAlerts = [];
          var errorAlertTemplate = {
            type: 'danger',
            container: '#alerts-container',
            animation: 'am-fade-and-slide-top',
            show: true
          };

          if (angular.isDefined($scope.formParams.filters.priceLow)) {
            if (!isValidFloat($scope.formParams.filters.priceLow)) {
              errorAlerts.push(
                // DRY Lazyness
                angular.extend(
                  errorAlertTemplate,
                  {
                    title: 'Advanced Search Filter Error!',
                    content: 'Invalid min price'
                  }
                )
              );
            }
          }

          if (angular.isDefined($scope.formParams.filters.priceHigh)) {
            if (!isValidFloat($scope.formParams.filters.priceHigh)) {
              errorAlerts.push(
                // MOAR DRY Lazyness
                angular.extend(
                  errorAlertTemplate,
                  {
                    title: 'Advanced Search Filter Error!',
                    content: 'Invalid max price'
                  }
                )
              );
            }
          }

          if ($scope.formParams.filters.deliveryType == 'collect') {
            if (!!$scope.formParams.filters.maxDistance && !isValidFloat($scope.formParams.filters.maxDistance)) {
              errorAlerts.push(
                // MOAR DRY Lazyness
                angular.extend(
                  errorAlertTemplate,
                  {
                    title: 'Advanced Search Filter Error!',
                    content: 'Invalid max distance'
                  }
                )
              );
            }

            if (!!$scope.formParams.filters.maxDistance) {
              if (!$scope.formParams.filters.postcode) {
                errorAlerts.push(
                  // MOAR DRY Lazyness
                  angular.extend(
                    errorAlertTemplate,
                    {
                      title: 'Advanced Search Filter Error!',
                      content: 'Invalid max distance'
                    }
                  )
                );
              }
            }
          }

          return (!!errorAlerts.length ? errorAlerts : false);
        }

        function gotoAccountActivation () {
          $location.path('/register');
        }

        function buildFormParams () {
          return {
            isMotoringCategory: false,
            selectedCategory: '',
            searchLocation: 'EBAY-GB',
            keywords: '',
            filters: {
              priceLow: undefined,
              priceHigh: undefined,
              deliveryType: 'all',
              condition: 'all',
              maxDistance: undefined,
              postcode: undefined,
              freePostage: false,
              sellerType: 'all',
              domesticSellerOnly: undefined,
            }
          };
        }

        function getRandomTip () {
          return [
            'Try searching by category only for the best chance of catching new items.',
            'The results page will update automatically as new listings appear. Be ready for bargains!',
            'Enable notifications to get alerted when new items are listed!'
          ][parseInt(Math.random() * 3)];
        }

        function init () {
          // Check if user is logged in
          userService.isUserLoggedIn(function(error, user) {
            if(error) {
              errorService.delegateError(error);
            }

            $scope.user = user;

            $scope.formParams.searchLocation = user.location;
          });

          requestService.stopQueryLoop();

          requestService.getMostRecentSearches(function(error, searches) {
            if(!error) {
              $scope.previousSearches = _.map(searches, function (search) {
                // Mitigate json header returning & as html entity &amp;
                search.keywords = _.unescape(search.keywords);
                search.expand = false;
                search.canExpand = !(angular.isArray(search.filters));
                return search;
              });
            }
          });
        }
      }
    ]);

}(window.angular, window._));
