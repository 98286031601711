; (function(angular) {

  'use strict';

  angular
    .module('catcherUtils')
    .directive('scrollStick', [
      '$window',
      function($window) {

        return {
          scope: {
            className: '@',
            threshold: '@',
            stuck: '='
          },
          link: function(scope, element) {
            var _window = angular.element($window);

            scope.stuck = false;

            var handleScrollChecking = function (data) {

              var stuck;

              if (!scope.stuck && $window.scrollY >= scope.threshold) {
                stuck = true;
                element.addClass(scope.className);
              } else if (scope.stuck && $window.scrollY < scope.threshold) {
                stuck = false;
                element.removeClass(scope.className);
              }

              if (stuck !== undefined) {
                scope.$apply(function () {
                  scope.stuck = stuck;
                });
              }
            };

            // Add hanlder for desktop scroll
            _window.on('scroll', handleScrollChecking);

            // Add touch handlers for mobile devices
            _window.on('touchstart', handleScrollChecking);
            _window.on('touchmove', handleScrollChecking);
            _window.on('touchend', handleScrollChecking);

            handleScrollChecking();
          }
        };
      }
    ]);

}(window.angular, window));
