; (function(angular) {

  'use strict';

  var ebayQuickListerApp = angular.module('EbayQuickListerApp', [
    'ngRoute',
    'mgcrea.ngStrap',
    'ngTouch',
    'angulartics',
    'angulartics.google.analytics',
    'ngAnimate',
    'toastr',
    'templates',
    'catcherUtils',
    'catcherErrorManagement',
    'catcherMessaging',
    'catcherNavBar',
    'catcherUserManagement',
    'catcherSearch'
  ]);

  ebayQuickListerApp.config(
    [
      '$routeProvider',
      '$locationProvider',
      function($routeProvider, $locationProvider) {

        $locationProvider.html5Mode(true);

        $routeProvider
          .otherwise({
            redirectTo: '/login'
          });
      }
    ]
  );

  ebayQuickListerApp.run([
    '$rootScope',
    '$location',
    '$window',
    'notificationService',
    function($rootScope, $location, $window, notificationService) {
      $rootScope.currentError = undefined;
      $rootScope.messages = [];
      $rootScope.unreadMessages = false;

      $rootScope.notificationsSupported = notificationService.doesBrowserSupportNotification;

      $rootScope.go = function (location) {
        $location.path(location);
      };

      $rootScope.scrollTop = function () {
        $window.scrollTo(0, 0);
      };
    }
  ]);

}(window.angular));

