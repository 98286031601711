; (function(angular) {

  'use strict';

  angular
    .module('catcherErrorManagement')
    .config(
      [
        '$routeProvider',
        function($routeProvider) {
          $routeProvider
            .when('/error', {
              templateUrl: 'catcher/error.partial.html',
              controller: 'errorController'
            });
        }
      ]
    );

}(window.angular));
