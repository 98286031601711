; (function(angular, _) {

  'use strict';

  angular
    .module('catcherUserManagement')
    .controller('registrationController', [
      '$scope', 
      '$location', 
      'toastr', 
      '$routeParams', 
      'userService',
      'requestService', 
      function($scope, $location, toastr, $routeParams, userService, requestService) {

        $scope.registrationWaiting = true;

        if($routeParams.activationToken !== undefined) {

          $scope.registrationWaiting = true;

          userService.isUserLoggedIn(function(error, user) {

            if(user.zoneId == '1') {
              $location.path('/search');
            } else {
              userService.activateAccount($routeParams.activationToken, function(error, user) {
                $scope.registrationWaiting = false;
                if(error) {
                  $scope.registrationStage = 3;
                } else {
                  $scope.registrationStage = 2;
                  $scope.loggedIn = !!user;
                }
              });
            }

          });

        } else {

          userService.isUserLoggedIn(function(error, user) {
            if(error) return;

            $location.path('/search');
          });

          requestService.stopQueryLoop();

          $scope.registrationWaiting = false;

          $scope.registrationStage = 1;
        }

        $scope.formParams = {
          email:            '',
          name:             '',
          location:         '',
          password:         '',
          confirmPassword:  '',
          agreeTerms:       false,
          agreeMailingList: false
        };

        var validateFormFields = ['email', 'name', 'location', 'password', 'confirmPassword', 'agreeTerms'];

        $scope.processFormParams = function() {

          var valid = true;

          _.each(validateFormFields, function(formParam) {
            if(!$scope.formParams[formParam]) {
              valid = false;
            }
          });

          var alertParams = {};

          if(valid) {

            $scope.registrationWaiting = true;

            userService.register(
              $scope.formParams.email,
              $scope.formParams.password,
              $scope.formParams.name,
              $scope.formParams.location,
              $scope.formParams.agreeMailingList,
              function(error) {

                if(error && error.code == 501) {

                  alertParams.title = 'Registration details are incorrect.';
                  alertParams.content = 'Please check your registration parameters.';

                  toastr.error(alertParams.content, alertParams.title);

                } else if(error && error.code == 502) {

                  alertParams.title = 'A user is already registered with the supplied email';
                  alertParams.content = 'Please choose a different email address.';

                  toastr.error(alertParams.content, alertParams.title);

                } else if(error && error.code == 404) {

                  alertParams.title = 'Could not connect to server.';
                  alertParams.content = 'Please check your internet connection and try again.';

                  toastr.error(alertParams.content, alertParams.title);

                } else {

                  $location.path('/search');

                }

                $scope.registrationWaiting = false;

              }
            );

          } else {

            alertParams.title = 'Could not register.';
            alertParams.content = 'Please check all fields.';

            toastr.error(alertParams.content, alertParams.title);

          }
        };

        $scope.loggedIn = false;

        $scope.acceptTerms = function (reject) {
          if (reject !== undefined) {
            $scope.formParams.agreeTerms = false;
          } else {
            $scope.formParams.agreeTerms = true;
          }
        };

        $scope.retryActivation = function() {
          $scope.registrationWaiting = true;
          window.location.reload();
        };

        $scope.redirectToSearch = function() {
          $location.path('/');
        };

        $scope.redirectToLogin = function() {
          $location.path('/login');
        };

      }
    ]);

}(window.angular, window._));
