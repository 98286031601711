; (function (angular) {

  'use strict';

  angular
    .module('catcherUserManagement')
    .service('userService', [
      '$http',
      '$rootScope',
      'errorService',
      function ($http, $rootScope, errorService) {
        var userService = this;

        $rootScope.currentUser = undefined;

        this.login = function (email, password, rememberMe, callback) {
          rememberMe = (rememberMe === undefined ? false : rememberMe);

          $http.post(
            '/ajax/login',
            {
              email   : email,
              password  : password,
              remember_me : rememberMe
            }
          ).then(function (response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(
                errorService.ERROR, 
                ''
              );
            } else if(data.error === 'BAD_LOGIN') {
              callback(
                errorService.INCORRECT_USER_LOGIN, 
                ''
              );
            } else {

              userService.equipUser(
                data.details.email_address,
                data.details.name,
                data.details.location,
                data.details.zone_id,
                data.details.postcode,
                data.details.can_email,
                callback
              );

            }

          },
          function () {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.register = function (email, password, name, location, canEmail, callback) {
          $http.post(
            '/ajax/register',
            {
              email   : email,
              password  : password,
              name    : name,
              location  : location,
              can_email : canEmail
            } 
          ).then(function (response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(
                errorService.ERROR, 
                ''
              );
            } else if(data.error === 'INVALID_REGISTRATION') {
              callback(
                errorService.INVALID_REGISTRATION,
                ''
              );
            } else if(data.error === 'USER_ALREADY_EXISTS') {
              callback(
                errorService.USER_ALREADY_EXISTS,
                ''
              );
            } else {
              userService.equipUser(
                data.details.email_address,
                data.details.name,
                data.details.location,
                data.details.zone_id,
                data.details.postcode,
                data.details.can_email,
                callback
              );
            }

          },
          function () {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.resendActivationEmail = function (callback) {
          $http.post(
            '/ajax/resendAccountWelcomeEmail',
            {}
          ).then(function (response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(
                errorService.ERROR, 
                ''
              );
            } else if(data.error === 'NOT_LOGGED_IN') {
              callback(
                errorService.NOT_LOGGED_IN, 
                ''
              );
            } else {
              callback(
                false,
                true
              );
            }

          },
          function () {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.activateAccount = function (activationToken, callback) {
          $http.post(
            '/ajax/activateAccount',
            {
              activation_token: activationToken
            }
          ).then(function (response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(
                errorService.ERROR, 
                ''
              );
            } else if(data.error === 'COULD_NOT_ACTIVATE') {
              callback(
                errorService.INVALID_ACTIVATION, 
                ''
              );
            } else if(data.error === 'INVALID_ACTIVATION') {
              callback(
                errorService.INVALID_ACTIVATION, 
                ''
              );
            } else {
              if(data.details) {
                userService.equipUser(
                  data.details.email_address,
                  data.details.name,
                  data.details.location,
                  data.details.zone_id,
                  data.details.postcode,
                  data.details.can_email,
                  callback
                );
              } else {
                callback(false, false);
              }
            }
          },
          function () {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.updateAccount = function (updateParams, callback) {

          $http.post(
            '/ajax/updateAccount',
            updateParams
          ).then(function (response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(
                errorService.ERROR, 
                ''
              );
            } else if(data.error === 'NOT_LOGGED_IN') {
              callback(errorService.NOT_LOGGED_IN, '');
            } else if(data.error === 'USER_ALREADY_EXISTS') {
              callback(errorService.USER_ALREADY_EXISTS, '');
            } else if(data.error === 'INVALID_SUBMISSION') {
              callback(errorService.INVALID_SUBMISSION, '');
            } else {
              userService.equipUser(
                data.details.email_address,
                data.details.name,
                data.details.location,
                data.details.zone_id,
                data.details.postcode,
                data.details.can_email,
                callback
              );
            }
          },
          function () {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.sendPasswordReset = function (updateParams, callback) {

          $http.post(
            '/ajax/sendPasswordRecoveryEmail',
            updateParams
          ).then(function (response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(errorService.ERROR, '');
            } else if(data.error === 'INVALID_SUBMISSION') {
              callback(errorService.INVALID_SUBMISSION, '');
            } else {
              callback(false, false);
            }
          },
          function () {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.ResetPassword = function (updateParams, callback) {

          $http.post(
            '/ajax/forgotPassword',
            updateParams
          ).then(function (response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(
                errorService.ERROR, 
                ''
              );
            } else if(data.error === 'INVALID_SUBMISSION') {
              callback(errorService.INVALID_SUBMISSION, '');
            } else {
              callback(false, false);
            }
          },
          function () {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.logout = function (callback) {

          $http.post('/ajax/logout', {}).then(function () {

            $rootScope.currentUser = undefined;

            callback(false, '');
          },
          function () {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.isUserLoggedIn = function (callback) {

          $http.post('/ajax/checkLogin', {}).then(function (response) {
            var data = response.data;

            if(typeof data === 'string') {
              callback(errorService.ERROR, '');
            } else if(data.error === 'NOT_LOGGED_IN') {
              userService.removeUser();
              callback(
                errorService.NOT_LOGGED_IN,
                'No user is logged in.'
              );
            } else if(data.error === 'BAD_XSRF_TOKEN') {
              userService.removeUser();

              callback(errorService.BAD_XSRF_TOKEN, 'Bad XSRF Token.');
            } else {
              if($rootScope.currentUser === undefined) {
                userService.equipUser(
                  data.details.email_address,
                  data.details.name,
                  data.details.location,
                  data.details.zone_id,
                  data.details.postcode,
                  data.details.can_email,
                  callback
                );
              } else {
                callback(false, $rootScope.currentUser);
              }
            }
          },
          function () {
            callback(errorService.COULD_NOT_CONNECT, '');
          });
        };

        this.equipUser = function (email, name, location, zoneId, postcode, canEmail, callback) {
          $rootScope.currentUser = {
            email:    email,
            name:   name,
            location: location,
            zoneId:   zoneId,
            postcode: postcode,
            canEmail: canEmail
          };

          callback(false, $rootScope.currentUser);
        };

        this.removeUser = function () {
          $rootScope.currentUser = undefined;
        };

        this.getUser = function () {
          return $rootScope.currentUser;
        };

        this.downloadPersonalInformation = function (callback) {
          window.location.href = '/user_export';
        };

        this.deletePersonalInformation = function (callback) {
          window.location.href = '/user_delete';
        };
      }
    ]);

}(window.angular));
