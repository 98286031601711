; (function(angular) {

  'use strict';

  angular
    .module('catcherMessaging')
    .directive('sendMessage', [
      'messageService',
      'errorService',
      'toastr',
      '$location',
      function(messageService, errorService, toastr, $location) {
        return {
          restrict: 'E',
          scope: {
            isReply: '@'
          },
          templateUrl: 'catcher/messaging/send-message.directive.html',
          link: function(scope, element) {

            var initFormParams = function() {
              scope.formParams = {
                subject: '',
                body: ''
              };
            };

            var validateFormParams = function() {
              var failFields = [];

              if(scope.formParams.subject === '' && !scope.isReply) {
                failFields.push('subject');
              }

              if(scope.formParams.body === '') {
                failFields.push('body');
              }

              return (failFields.length ? failFields : false);
            };

            scope.cancelMessage = function() {
              scope.composingMessage = false;
              initFormParams();
            };

            scope.composeMessage = function() {
              scope.composingMessage = true;
            };

            scope.sendMessage = function() {

              var validatedFormParams = validateFormParams();

              if(!validatedFormParams) {

                scope.messageWating = true;

                messageService.sendMessage(
                  scope.formParams.subject, 
                  scope.formParams.body, 
                  scope.isReply,
                  function(error, data) {
                    scope.messageWating = true;

                    if(error) {
                      if(error.code === 101) {
                        errorService.delegateError(error);
                      } else {
                        toastr.error('Please check your connection and try again.', 'Could not connect to server.');
                      }
                    } else {
                      toastr.success('Message successfully sent!');

                      $location.path('/support/' + data.id);
                    }
                  }
                );

              } else {
                toastr.error('Fields: ' + validatedFormParams.join(', ') + ' are invalid', 'Please check submission');
              }

            };

            scope.sendWaiting = false;
            scope.isReply = (scope.isReply !== undefined ? scope.isReply : false);
            scope.composingMessage = false;
            initFormParams();

          }
        };
      }
    ]);

}(window.angular));

